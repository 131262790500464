<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-file-clock</v-icon>
          ADMINISTRAR DAES ADMIN
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="busqueda"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn small class="mx-2" color="primary" @click="abrirFormDae()">
          <v-icon left>mdi-plus-circle-outline</v-icon> Nueva DAE
        </v-btn>

        <v-col cols="3">
          <v-btn class="mx-4" small color="primary" @click="cargarArchivo()">
            <v-icon left>mdi-upload</v-icon> Importar DAEs
          </v-btn>
          <v-file-input
            style="display: none"
            id="imporatFi"
            class="pb-0 mb-0"
            label="Iportar desde archivo"
            v-model="archivoImp"
            @change="importarDaaes()"
          ></v-file-input>
        </v-col>
      </v-toolbar>
      <ListaDaesFincaComponent
        fincaId=""
        @abrirFormDae="abrirFormDae"
        @cargarLista="cargarLista"
        @handlePageChange="handlePageChange"
        :lista="lista"
        :pageCount="pageCount"
        :tipoEmpresa="tipoEmpresa"
        :currentPage.sync="currentPage"
        :itemsPerPage="itemsPerPage"
      >
      </ListaDaesFincaComponent>
    </v-card>

    <!-- DIALOG DAE -->
    <v-dialog v-model="dialogDae" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-file-clock</v-icon>
          <span class="headline">{{ formDaeTitulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formDae">
              <v-row v-if="1 == 2">
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field v-model="datosDae.daeId" label="Codigo">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-select
                    small
                    v-model="datosDae.daeNumAduana"
                    :rules="requiredRule"
                    :items="[
                      { codigo: '055', desc: '[055] Quito' },
                      { codigo: '028', desc: '[028] Guayaquil Marítimo' },
                    ]"
                    item-text="desc"
                    item-value="codigo"
                    label="Ofic. Aduana"
                  >
                  </v-select>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    class="md-12"
                    small
                    v-model="datosDae.daeNumAnio"
                    label="Año"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="2">
                  <v-text-field
                    readonly
                    class="md-12"
                    maxlength="8"
                    small
                    v-model="datosDae.daeNumRegimen"
                    label="Regimen"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    class="md-12"
                    maxlength="8"
                    small
                    v-model="datosDae.daeNumSec"
                    label="Secuencia"
                    :rules="daeSecRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    label="Número de DAE"
                    v-model="numDae"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    small
                    :items="getAnios()"
                    item-text="anio"
                    item-value="num_anio"
                    label="Año"
                    :rules="requiredRule"
                    v-model="datosDae.anio"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    :rules="_reglasMes"
                    :items="months"
                    item-text="name"
                    item-value="number"
                    small
                    v-model="datosDae.mes"
                    label="Mes"
                  />
                </v-col>
              </v-row>

              <!--							<v-row>
								<v-col class="pt-0 pb-0" cols="12">
									<v-text-field type="date" :rules="requiredRule" small v-model="datosDae.desde" label="Desde" />

								</v-col>
							</v-row>

							<v-row>
								<v-col class="pt-0 pb-0" cols="12">
									<v-text-field type="date" :rules="requiredRule" small v-model="datosDae.hasta" label="Hasta" />

								</v-col>
							</v-row>
							-->

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    :rules="requiredRule"
                    v-model="datosDae.codigoPais"
                    small
                    item-text="NOMCOD"
                    item-value="PAIS_CODIGO"
                    :items="paises"
                    label="País de destino"
                    cache-items
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    small
                    v-model="datosDae.daeDestino"
                    label="Descripcion destino"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="primary" small @click="guardarDae">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn @click="dialogDae = !dialogDae" small>
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import ListaDaesFincaComponent from "./ListaDaesFincaComponent.vue";
import axios from "axios";

export default {
  name: "AdministrarDaesComponent",
  components: {
    ListaDaesFincaComponent,
  },
  data: () => ({
    overlay: false,
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Finca", value: "FINCA_NOMBRE" },
      { text: "Codigo", value: "FUE_ID" },
      { text: "Nombre", value: "FUE" },
      { text: "Destino", value: "DAE_DEST" },
      { text: "Válido hasta", value: "FECHA_CADUCA" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    daeSecRule: [(v) => v.length == 8 || "El número de tener 8 digitos"],
    daeAnioRule: [(v) => v.length == 8 || "El número de tener 8 digitos"],
    daeMesRule: [
      (v) =>
        v >= new Date().getMonth() + 1 ||
        "El mes debe ser mayor o igual al actual",
    ],

    lista: [],
    paises: [],
    options: {},

    notifications: false,

    daeEdit: false,
    busqueda: null,
    formDaeTitulo: "",

    dialogDae: false,
    datosDae: {
      daeId: "",
      daeDestino: "",
      anio: "",
      mes: "",
      desde: "",
      hasta: "",
      fincaId: "",
      daeNumAduana: "",
      daeNumAnio: "",
      daeNumRegimen: "40",
      daeNumSec: "",
      daeNum: "",
      codigoPais: "",
    },

    itemsPerPage: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    tipoEmpresa: null,
    archivoImp: "",
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months", "token", "url"]),

    ...mapGetters("access", []),
    numDae() {
      return (
        this.datosDae.daeNumAduana +
        "-" +
        this.datosDae.daeNumAnio +
        "-" +
        this.datosDae.daeNumRegimen +
        "-" +
        this.datosDae.daeNumSec
      );
    },
    _headers() {
      let head2 = [];
      for (let i = 0; i < this.headers.length; i++) {
        if (this.tipoEmpresa == "1") {
          if (this.headers[i].value != "FINCA_NOMBRE") {
            head2.push(this.headers[i]);
          }
        } else {
          head2.push(this.headers[i]);
        }
      }

      return head2;
    },
    _reglasMes() {
      if (this.datosDae.anio == new Date().getFullYear().toString()) {
        if (this.datosDae.mes < new Date().getMonth() + 1) {
          return [false || "El mes debe ser mayor o igual"];
        }

        if (this.datosDae.mes > new Date().getMonth() + 2) {
          return [false || "El mes debe ser igual al actual"];
        }
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    getAnios() {
      let anio = new Date().getFullYear();
      let mes = new Date().getMonth();

      let items = [{ num_anio: anio, anio: anio }];
      let anioSig = { num_anio: anio + 1, anio: anio + 1 };
      if (mes == 11) items.push(anioSig);

      return items;
    },
    guardarDae() {
      if (!this.$refs.formDae.validate()) {
        return;
      }
      if (this.daeEdit == false) {
        this.setUrl("api/dae");
        this.requestApi({
          method: "POST",
          data: {
            dae_num: this.numDae,
            fecha_caduca: this.datosDae.hasta,
            fecha_inicio: this.datosDae.desde,
            ente_id: this.datosDae.fincaId,
            finca_id: this.datosDae.fincaId,
            destino: this.datosDae.daeDestino,
            cod_pais: this.datosDae.codigoPais,
            mes: this.datosDae.mes,
            anio: this.datosDae.anio,
          },
        })
          .then((res) => {
            console.log(res);
            this.cargarLista();
          })
          .catch(() => {})
          .then(() => {
            this.dialogDae = false;
          });
      }
      if (this.daeEdit == true) {
        this.setUrl("api/dae/" + this.datosDae.daeId);
        this.requestApi({
          method: "PUT",
          data: {
            dae_id: this.datosDae.daeId,
            dae_num: this.numDae,
            fecha_caduca: this.datosDae.hasta,
            fecha_inicio: this.datosDae.desde,
            ente_id: this.datosDae.fincaId,
            finca_id: this.datosDae.fincaId,
            destino: this.datosDae.daeDestino,
            cod_pais: this.datosDae.codigoPais,
            anio: this.datosDae.anio,
            mes: this.datosDae.mes,
          },
        })
          .then((res) => {
            console.log(res);
            this.cargarLista();
          })
          .catch(() => {})
          .then(() => {
            this.dialogDae = false;
          });
      }
    },

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-daes-finca");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          busqueda: this.busqueda,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.paises = res.data.paises;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          //this.tipoEmpresa = res.data.tipoEmpresa
          console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirFormDae(daeid = null) {
      this.datosDae.daeId = "";
      this.datosDae.daeNum = "";
      this.datosDae.daeDestino = "";
      this.datosDae.codigoPais = "";
      this.datosDae.desde = "";
      this.datosDae.hasta = "";
      this.datosDae.fincaId = "";
      this.datosDae.daeNumAduana = "";
      this.datosDae.daeNumAnio = new Date().getFullYear().toString();
      this.datosDae.daeNumRegimen = "40";
      this.datosDae.daeNumSec = "";
      (this.datosDae.anio = new Date().getFullYear().toString()),
        (this.datosDae.mes = ("0" + (new Date().getMonth() + 1)).slice(-2)),
        (this.dialogDae = true);
      this.daeEdit = false;

      this.formDaeTitulo = "Nueva DAE";

      if (daeid) {
        this.daeEdit = true;
        this.formDaeTitulo = "Editar DAE";
        this.setUrl("api/dae/" + daeid);
        this.requestApi({
          method: "GET",
          data: {},
        })
          .then((res) => {
            //console.log(res.data.dae)
            this.datosDae.daeId = daeid;
            this.datosDae.daeNumAduana = res.data.dae.FUE.split("-")[0];
            this.datosDae.daeNumAnio = res.data.dae.FUE.split("-")[1];
            this.datosDae.daeNumRegimen = res.data.dae.FUE.split("-")[2];
            this.datosDae.daeNumSec = res.data.dae.FUE.split("-")[3];

            this.datosDae.daeNum = res.data.dae.FUE;
            this.datosDae.desde = res.data.dae.FECHA_INICIO;
            this.datosDae.hasta = res.data.dae.FECHA_CADUCA;
            this.datosDae.daeDestino = res.data.dae.DESTINO;
            this.datosDae.codigoPais = res.data.dae.COD_PAIS;
            this.datosDae.fincaId = res.data.dae.FINCA_ID;
            this.formDae = true;
          })
          .then(() => {});
      }
    },

    eliminarDae(permissionId) {
      Vue.swal({
        html: "Está seguro de eliminar el permiso?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("user-security-group");
          this.requestApi({
            method: "DELETE",
            data: {
              securityGroupPermissionId: this.securityGroupId,
              permissionId: permissionId,
              typeDelete: "eliminarPermiso",
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.detail } });
            let obj = this.permisos.find(
              (e) => e.permission_id == permissionId
            );
            let index = this.permisos.indexOf(obj);
            this.permisos.splice(index, 1);
          });
        }
      });
    },

    cargarArchivo() {
      document.getElementById("imporatFi").click();
    },
    importarDaaes() {
      let formData = new FormData();
      formData.append("archivo", this.archivoImp);
      formData.append("user_name", this.user.user_login_id);
      //formData.append("cabecera_id", this.datosGuia.CABECERA_ID);
      //formData.append("cliente_id", this.datosGuia.CLIENTE_ID);
      //formData.append("awb", this.datosGuia.AWB);
      //console.log(this.archivoSelecionados);
      this.overlay = true;
      this.setUrl("api/importar-daes");
      //alert(this.token);

      axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.overlay = false;

          //this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
          this.alertNotification({
            param: {
              html: res.data.msg,
            },
          });
        })
        .catch((err) => {
          this.overlay = false;
          this.alertNotification({
            param: {
              html: err.data.msg,
            },
          });
        })
        .finally((err) => {
          this.overlay = false;
        });
    },

    handlePageChange() {
      this.cargarLista();
    },
  },
  mounted() {
    //this.$vuetify.lang.current = 'es'
    this.setTitleToolbar("ADMINISTRAR DAES");
    this.cargarLista();
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
